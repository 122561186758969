<template>
    <div ref="noteList">
        <template v-if="loading">
            <LoadingSpinner class="col-12" />
        </template>
        <template v-else-if="notes.length == 0">
            <div class="box">
                <span class="text-center">
                    <i>{{ $t('global.no_result') }}</i>
                </span>
            </div>
        </template>
        <template v-else>
            <template v-for="note in notes">
                <div class="box feed" :key="note.notes_id">
                    <!-- Nom cheval + photo -->
                    <div class="row no-gutters mb-2">
                        <div class="col-auto mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.071" height="17.071" viewBox="0 0 17.071 17.071">
                            <g id="Groupe_1" data-name="Groupe 1" transform="translate(-980 -539.757)">
                                <rect id="Rectangle_3" data-name="Rectangle 3" width="5" height="5" rx="1" transform="translate(997.071 548.293) rotate(135)" fill="#fff"/>
                                <rect id="Rectangle_4" data-name="Rectangle 4" width="5" height="5" rx="1" transform="translate(987.071 548.293) rotate(135)" fill="#fff"/>
                                <rect id="Rectangle_5" data-name="Rectangle 5" width="5" height="5" rx="1" transform="translate(988.536 556.828) rotate(-135)" fill="#fff"/>
                                <rect id="Rectangle_6" data-name="Rectangle 6" width="5" height="5" rx="1" transform="translate(988.536 546.828) rotate(-135)" fill="#fff"/>
                            </g>
                            </svg>
                        </div>
                        <div v-if="!fromHorseFiche" class="col">
                            <router-link :to="{ name: 'horseFiche', params: { horse_id: note.horse.horse_id }}"><b>{{ note.horse.horse_nom | multiCapitalize }}</b></router-link>
                            <div class="date">{{ note.note_date }}</div>
                        </div>
                        <div v-else>
                            <div>{{ note.note_date }}</div>
                        </div>
                    </div>

                    <!-- Caroussel -->
                    <div class="media_carousel">
                        <e-carousel :medias="note.files" />
                    </div>
                    <!-- Description -->
                    <div class="row">
                        <div class="col-12">
                            <!-- 17-05-21 - Ne plus afficher les titres nul part -->
                            <!-- <h3 v-if="fromHorseFiche"><b>{{ note.note_title }}</b></h3> -->
                            <p v-html="note.note_comment"></p>
                        </div>
                    </div>
                </div>
            </template>

            <infinite-loading @infinite="onHitBottom">
                <div slot="spinner">
                    <LoadingSpinner class="col-12" />
                </div>
                <div slot="no-more">
                    {{ $t('notes.end_of_actus') }}
                </div>
                <div slot="no-results">
                    <div class="box">
                        <span class="text-center">
                            <i>{{ $t('global.no_result') }}</i>
                        </span>
                    </div>
                </div>
            </infinite-loading>
        </template>
    </div>
</template>


<script type="text/javascript">
    import NotesMixin from "@/mixins/Notes.js"

	export default {
		name: "NoteFeed",
        mixins: [NotesMixin],
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
        },
        props: {
            horse_id: { type: Number, default: 0 }
        },
        data() {
            return {
                notes: [],
                page: 1,
                loading: true,
                working: false
            }
        },
        computed: {
            fromHorseFiche() {
                return this.horse_id > 0
            }
        },
        created() {
            this.preinit_component()
        },
        methods: {
            preinit_component() {
                this.loadNotes()
            },
            async loadNotes($state=null) {
                if(this.fromHorseFiche) {
                    return this.getHorseNotesFeed(this.horse_id, this.page).then(res => {
                        this.notes = this.notes.concat(res)
                        if(res.length === 0 && $state) $state.complete()
                    }).finally(() => this.loading = false)
                }
                else {
                    return this.getNotesFeed(this.page).then(res => {
                        this.notes = this.notes.concat(res)
                        if(res.length === 0 && $state) $state.complete()
                    }).finally(() => this.loading = false)
                }
            },
            onHitBottom($state) {
                if (!this.working && !this.loading) {
                    this.working = true
                    this.page += 1

                    this.loadNotes($state)
                        .finally(() => {
                            this.working = false
                            $state.loaded()
                        })
                }
            }
        }
	}
</script>
